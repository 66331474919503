<template>
  <div class="bonuses">
    <div class="item__row item__ac bonuses__logo">
      <i class="mdi mdi-gift mr-2"></i>
    </div>

    <div class="item__row item__ac bonuses__header">
      <h3>{{ company_name }}</h3>
      <i class="mdi mdi-logout" @click="logoutAccount()"></i>
    </div>


    <div class="bonuses__search__area  item__row item__ac">
      <input
          v-model="searchText"
          @input="searchBonus"
          placeholder="Поиск клиента"
          class="bonuses__search"
          type="tel"
      />
    </div>

    <div class="item__column" v-if="showLoader">
      <div
          class="item__column bonuses__list mb-6"
          v-for="(bonus, index) in bonuses"
          :key="index"
      >
        <div class="item__row item__ac bonuses__right__actions">
          <i class="mdi mdi-account-edit-outline" @click="getBonus(bonus.id)"></i>
        </div>

        <div class="bonuses__list__name mr-2 mb-3">
          <i class="mdi mdi-account mr-1"></i>
          <p>{{ bonus.name }}</p>
        </div>
        <a class="bonuses__list__phone mr-2" :href="setWhatsappLink(bonus.phone,bonus)"><i class="mdi mdi-whatsapp mr-2"></i>{{ formatPhoneNumber(bonus.phone) }}</a>

        <p class="mr-10 bonuses__amount">
          <b v-if="settings && settings.type ==='bonus'">+{{ bonus.bonus }} ₸</b>
          <b v-else>
            <span class="bonuses__end" v-if="bonus.logs.length+1 === count_value">{{ bonus.logs.length  }} покупка ({{ count_value}}-я покупка бесплатно)</span>
            <span v-else>{{ bonus.logs.length }} покупка</span>
          </b>
        </p>


        <button
            v-if="settings.type ==='bonus'"
            class="bonuses__sub"
            @click="openFreshBonus(bonus.id)"
        >
          <p>Обнулить бонус</p>
        </button>



        <div class="item__column bonus__logs">
          <div class="bonus__logs--history item__row item__ac">

            <div class="bonus__logs--item">
              <i @click="openHistory(bonus.all_logs)" class="mdi mdi-history"></i>
            </div>

            <div class="bonus__logs--item" v-if="settings.type ==='bonus'">
              <i @click="openUseModal(bonus)" class="mdi mdi-minus"></i>
            </div>

            <div class="bonus__logs--item">
              <i @click="openAddModal(bonus)" class="mdi mdi-plus"></i>
            </div>

          </div>

        </div>


      </div>
    </div>

    <div class="item__column mb-2" v-else>
      <v-skeleton-loader
          class="mb-2"
          v-for="(jj, index) in 5"
          :key="index"
          type="article"
      ></v-skeleton-loader>
    </div>


    <v-dialog max-width="600" v-model="zero_bonus">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Обнулить бонус ?</p>

          <div class="modal__bonus__variants item__row item__ac">
            <button
                type="submit"
                class="modal__bonus__btn item__pointer modal__bonus__btn--green"
                @click="freshBonus"
            >
              <p>Да</p>
            </button>

            <button
                type="submit"
                class="modal__bonus__btn item__pointer modal__bonus__btn--red"
                @click="zero_bonus=false"
            >
              <p>Нет</p>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="bonus_use">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Списать бонус</p>
          <div class="item__column modal__bonus__result">
            <p>{{ info.name }}</p>
            <p class="mb-2 mt-3">{{ info.bonus !== 0 ? info.bonus + ' ₸' : '' }} </p>
          </div>
          <div class="item__column modal__bonus__label">
            <p class="mb-4">Телефон</p>
            <p>{{ info.phone }}</p>
          </div>
          <div class="item__column modal__bonus__label">
            <p>Сумма к списанию</p>
            <input type="tel" v-model="sub_amount" required/>
          </div>

          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="useBonus()"
          >
            <p>Списать бонус</p>
          </button>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="bonus_add">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Добавить бонус</p>

          <span class="bonuses__end" v-if="info.logs && info.logs.length+1 === count_value">{{ info.logs.length }} ({{ count_value}}-я покупка бесплатно)</span>

          <div class="item__column modal__bonus__result">
            <p>{{ info.name }}</p>
            <p class="modal__bonus__result--value"  v-if="settings.type ==='bonus'">{{ info.bonus !== 0 ? info.bonus + ' ₸' : '' }} </p>
            <p class="modal__bonus__result--value" v-else> {{ info.logs ? info.logs.length + ' покупка' : '' }}</p>

          </div>

          <div class="item__column modal__bonus__label">
            <p>Телефон</p>
            <p>{{ info.phone }}</p>
          </div>

          <div class="item__column modal__bonus__label">
            <p>Сумма</p>
            <input v-model="amount_price" type="tel"/>
          </div>

          <div
              v-if="settings.type !=='bonus'"
              class="item__column modal__bonus__label"
          >
            <p>Количество</p>
            <input v-model="count" type="tel"/>
          </div>

          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="addBonus()"
          >
            <p>Добавить бонус</p>
          </button>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="showHistory" max-width="500">
      <v-card>
        <div class="bonus__history">
          <h3 class="mb-4">История покупок</h3>
          <div
              v-for="item in logs"
              :key="item.id"
              class="item__column mb-4"
          >
            <p><b>{{ formatDate(item.created_at) }}</b></p>
            <p>Покупка:    <b>{{ item.price }} тнг</b> </p>
            <p>Бонус: <b>{{ item.bonus }}</b></p>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateDialog" max-width="500">
      <v-card>
        <form
            action=""
            @submit.prevent="updateBonusForm"
            class="green__column item__column item_ac green__message"
            style="padding: 20px"
        >
          <div class="green__column green__input">
            <p>Имя</p>
            <v-text-field
                v-model="name"
                label="Номер карты"
                required
                outlined
            ></v-text-field>
          </div>
          <div class="green__column green__input">
            <p>Номер телефона</p>
            <v-text-field
                v-model="phone"
                label="Номер телефона"
                required
                outlined
            ></v-text-field>
          </div>
          <div class="green__column green__input">
            <p>Цена</p>
            <v-text-field
                v-model="price"
                label="Цена"
                required
                outlined
            ></v-text-field>
          </div>
          <button type="submit" class="button">Отправить</button>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: ["bonusesList"],
  data() {
    return {
      showLoader: false,
      statistics: [],
      bonuses: [],
      updateDialog: false,
      phone: "",
      card_number: "",
      price: "",
      searchText: "",
      bonus: "",
      name: "",
      logs: [],
      showHistory: false,
      info: '',
      sub_amount: 0,
      amount_price: 0,
      count: 0,
      bonus_use: false,
      bonus_add: false,
      bonus_value: '',
      count_value: '',
      company_name: '',
      company: '',
      settings: '',
      zero_bonus: false,
      selected_bonus_id: 0
    };
  },

  methods: {
    callTodayAmount() {
      this.$emit('today-amount');
    },
    openFreshBonus(id) {
      this.zero_bonus=true
      this.selected_bonus_id = id
    },
    freshBonus() {
      let data = {}
      this.$axios({
        method: 'put',
        url: `${this.$API_URL}bonus/fresh/${this.selected_bonus_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses()
            this.zero_bonus = false
            this.selected_bonus_id = 0
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    logoutAccount() {
      localStorage.setItem('access_token','')
      this.$router.push('/')
    },
    setWhatsappLink(phone, bonus) {
      let message = `
      Құрметті клиент\nСіздің жиналған бонусыңыз: ${bonus.bonus} теңге. \n © ${this.company_name}.
      \nУважаемый клиент\nВаш накопленный бонус: ${bonus.bonus} тенге. \n С уважением,  © ${this.company_name}`;

      return `https://wa.me/${phone.replace("8", "7")}?text=${encodeURIComponent(message)}`;
    },
    formatDate(date) {
      return date;
    },
    useBonus() {
      if (this.info.phone.replace(/[^0-9]/g, "").length !== 11) {
        this.$toast.open({
          message: "Введите телефон",
          type: "error",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      } else if (!this.sub_amount) {
        this.$toast.open({
          message: "Введите сумму к списанию",
          type: "error",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }
      let data = {
        phone: this.info.phone.replace(/[^0-9]/g, ""),
        sub_amount: this.sub_amount
      }

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/use`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
            this.bonus_use = false
            this.sub_amount = 0
            this.callTodayAmount()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    addBonus() {
      if (this.amount_price === "") {
        this.$toast.open({
          message: "Заполните поле сумма",
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }

      let data = {
        phone: this.info.phone.replace(/[^0-9]/g, ""),
        amount: this.amount_price,
        name: this.info.name,
        count: this.count
      };


      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: "Бонус успешно добавлено",
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
            this.phone = "";
            this.amount_price = ""
            this.bonus_add = false
            this.count = 0
            this.callTodayAmount()
            // this.$parent.getTodayAmount()
            // window.location.reload()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    openAddModal(bonus) {
      this.info = bonus
      this.bonus_add = true
    },
    openUseModal(bonus) {
      this.info = bonus
      this.bonus_use = true
    },
    openHistory(logs) {
      this.showHistory = true
      this.logs = logs
    },
    formatPhoneNumber(phoneNumberString) {
      let filter = "";
      if (phoneNumberString.slice(0, 1) == "8") {
        filter = phoneNumberString.replace("8", "");
      }

      let phone_number =  "8 " +
          filter.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")
      return phone_number;
    },
    debouncedSearchBonus: debounce(function() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/search?search=${this.searchText}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.bonuses = res.data;
          })
    }, 1000),
    searchBonus() {
      this.debouncedSearchBonus();
    },
    updateBonusForm() {
      let data = {
        id: this.bonus.id,
        name: this.name,
        phone: this.phone,
        price: this.price,
      };
      this.$http
          .post("/update/bonus", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((res) => {
            this.phone = "";
            this.price = "";
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.updateDialog = false;
            this.getBonuses();
          });
    },
    deleteBonus(id) {
      confirm("Удалить запись?");

      let data = {
        id: id,
      };
      this.$http
          .post("/delete/bonus", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
          });
    },
    getBonuses() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.bonuses = res.data;
            setTimeout((this.showLoader = true), 5000);
          });
    },
    getBonus(id) {
      this.updateDialog = true;
      const config = {
        headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`},
      };
      this.bonus = "";
      this.$http.get("/get/bonus/by/id?id=" + id, config).then((res) => {
        this.name = res.data.name;
        this.bonus = res.data;
        this.phone = res.data.phone;
        this.price = res.data.bonus;

        console.log(res.data);

        this.name = res.data.name;
        this.card_number = res.data.card_number;
      });
    },
    getUser() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/user`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.settings = res.data.settings
            this.bonus_value = res.data.settings.bonus_value
            this.count_value = res.data.settings.count_value
            this.company_name = res.data.company.name
            this.company = res.data.company
          })
    },
  },
  computed: {},
  watch: {
    bonusesList(val) {
      this.bonuses = val;
    },
    searchText(val) {
      if (val) {
        this.searchBonus();
      } else {
        this.getBonuses();
      }
    },
  },
  mounted() {
    this.getBonuses();
  },
  beforeMount() {
    this.getUser()
  }
};
</script>

<style lang="scss" scoped>

.bonus__history {
  padding: 20px;
  min-height: 300px;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  opacity: 0.8;
}

.statistics {
  margin-top: 20px;

  .statistics__list {
    overflow-x: auto;
  }

  .statistics__list::-webkit-scrollbar {
    height: 1px;
  }

  .statistics__list::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }

  .statistics__list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }

  .statistics__title {
    font-size: 22px;
    color: white;
  }

  .item__statistic {
    margin-right: 20px;
    background-image: linear-gradient(
            to right,
            #253532,
            #1e363d,
            #273345,
            #3c2e43,
            #4c2934
    );
    min-width: 150px;
    padding: 20px;
    border-radius: 20px;
  }

  .item__statistic__month {
    color: white;
  }

  .item__statistic__freq {
    font-size: 22px;
    color: white;
    white-space: nowrap;
    color: #529a6d;
  }
}

.bonuses {
  margin-bottom: 60px;

  .bonuses__logo {
    font-size: 28px;

    i {
      color: white;
    }

    p {
      color: white;
    }
  }
}

.bonuses__search__area {
  border: 1px solid white;
  display: flex;
  margin-top: 10px;
  padding: 10px;

  i {
    color: black;
    font-size: 25px;
  }

  .bonuses__search {
    align-self: center;
    width: 100%;
    border-radius: 10px;
    color: #333333 !important;
    outline: none;
    border: 1px solid #333333;
    padding: 20px;
  }
}

.bonuses__amount {
  color: #529a6d !important;
  font-size: 18px;
}

.bonuses__result {
  color: white;
  font-size: 18px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
    font-size: 24px;
  }

  .bonuses__result__text {
    font-size: 14px;
  }
}

::placeholder {
  color: #333333;
}

.bonuses__list {
  min-height: 180px;
  align-self: center;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin-bottom: 40px;
  outline: none;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  p {
    color: #333333;
  }

  .bonuses__right__actions {
    font-size: 24px;

    .mdi-account-edit-outline {
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
    }
  }

  .bonuses__count {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 24px;
    border-radius: 50%;
    border: 1px solid white;

    span {
      font-size: 14px;
    }
  }

  //background-image: linear-gradient(
  //        to right,
  //        #253532,
  //        #1e363d,
  //        #273345,
  //        #3c2e43,
  //        #4c2934
  //);

  @media (max-width: 900px) {
    width: 95%;
    margin-bottom: 40px;
  }

  .bonuses__list__name {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-weight: bold;
    }
  }

  .bonuses__delete {
    margin-top: 15px;
    color: white;
    font-weight: bold;
  }

  .bonuses__delete:hover {
    opacity: 0.7;
  }

  .bonuses__list__date {
    width: 200px;

    p {
      white-space: nowrap !important;
      color: white;
    }
  }
}

p {
  margin-bottom: 0;
}

.main__next {
  cursor: pointer;
  background: var(--main-kenes-blue);
  padding: 10px;
  margin-bottom: 20px;
  color: white;
  width: 200px;
  font-weight: bold;
  align-self: center;
  @media (max-width: 900px) {
    width: 100%;
  }
}

.main__next:hover {
  opacity: 0.8;
  color: black;
}

.set__category {
  flex-wrap: wrap;

  .set__category__input {
    width: 500px;
  }
}

.set__category__item {
  border-radius: 4px;
  width: 500px;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
  rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  @media (max-width: 900px) {
    width: 100%;
  }

  .set__category__btn {
    padding: 10px;
    border: 1px solid black;
    color: white;
    background-color: var(--main-kenes-blue);
  }
}

.modal {
  padding: 20px;

  .modal__v {
    width: 150px;

    .modal__round {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}

fieldset {
  border-color: blue !important;
}

.green__message {
  align-items: center;

  .green__input {
    width: 100%;
  }
}

.bonus__logs--history {
  align-self: flex-end;
}

.bonus__logs {
  i {
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  .bonus__logs--item {
    margin-left: 15px;
    padding: 1px;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.bonuses__end {
  color: #1e7e34;
  font-weight: bold;
}


.modal__bonus {
  .modal__bonus__title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-bottom: 30px !important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px !important;
  }

  .modal__bonus__result {
    margin-bottom: 10px;

    p {
      font-size: 18px;
      color: #333333;

      span {
        font-weight: bold;

        font-size: 20px;
      }
    }
  }

  .modal__bonus__result--value {
    color: #1e7e34 !important;
  }

  .modal__bonus__label {
    margin-bottom: 15px;

    p {
      font-weight: bold;
    }
  }

  input {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
  }

  .modal__bonus__btn {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;

    p {
      color: white;
      font-weight: bold;
    }

    i {
      color: white;
    }
  }
}

.v-text-field--outlined fieldset {
  border-color: white;
}


.bonuses__list__phone {
  text-decoration: none;
  font-weight: bold;
  color: #529a6d;
  width: 175px;

  display: flex;
  align-items: center;
  margin-bottom: 18px !important;
}

.bonuses__header {
  justify-content: space-between;
  padding: 20px;

  h3 {
    padding :0;
  }
}

.bonuses__sub {
  background: #529a6d;
  padding: 10px;
  border: 0;
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 15px;


  p {
    margin-bottom: 0;
    color: white;
  }

}

.modal__bonus__variants {
  width: 100%;
  justify-content: space-around;

  button {
    width: 120px;
  }
}

.modal__bonus__btn--green {
    background: #529a6d !important;
}

.modal__bonus__btn--red {
    background: #e12910 !important;
}
</style>
